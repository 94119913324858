import React from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";

const ProgressTracker = ({ jobsCount, coverLetterCount }) => {
  // Update progressData to use the props
  const progressData = [
    { name: "Jobs added", value: jobsCount, color: "#4981fd" },
    { name: "Cover letters", value: coverLetterCount, color: "#ffa043" },
    { name: "Interviews scheduled", value: 1, color: "#20c9ac" }, // Keep this value static if needed
  ];

  return (
    <div className="progress-tracker">
      <h2>Progress tracker</h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart className="pie">
          <Pie
            data={progressData}
            dataKey="value"
            nameKey="name"
            innerRadius="65%"
            outerRadius="92%"
            paddingAngle={5}
            cy={110}
          >
            {progressData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend className="legend" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProgressTracker;
