// ButtonsSection.js

import React from "react";
import ActionButton from "./ActionButton"; // Adjust the path as needed

// Generate Reports Icon
const GenerateReportsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect
      x="3.33"
      y="1.67"
      width="13.33"
      height="16.67"
      stroke="#4981FD"
      strokeWidth="2"
    />
    <rect
      x="11.67"
      y="1.67"
      width="5"
      height="5"
      stroke="#4981FD"
      strokeWidth="2"
    />
    <line
      x1="6.67"
      y1="10.83"
      x2="13.34"
      y2="10.83"
      stroke="#4981FD"
      strokeWidth="2"
    />
    <line
      x1="6.67"
      y1="14.17"
      x2="13.34"
      y2="14.17"
      stroke="#4981FD"
      strokeWidth="2"
    />
    <line
      x1="6.67"
      y1="7.5"
      x2="8.34"
      y2="7.5"
      stroke="#4981FD"
      strokeWidth="2"
    />
  </svg>
);

// Upload Resume Icon
const UploadResumeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M17.5 13.0945V16.4278C17.5 16.8698 17.3244 17.2938 17.0118 17.6063C16.6993 17.9189 16.2754 18.0945 15.8333 18.0945H4.16667C3.72464 18.0945 3.30072 17.9189 2.98816 17.6063C2.67559 17.2938 2.5 16.8698 2.5 16.4278V13.0945"
      stroke="#4981FD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1666 7.26115L9.99998 3.09448L5.83331 7.26115"
      stroke="#4981FD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.09448V13.0945"
      stroke="#4981FD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ButtonsSection = ({ openModal }) => {
  return (
    <div className="buttons-section">
      {/*<ActionButton
        icon={GenerateReportsIcon}
        text="Generate Reports"
        className="generate-reports-button"
      />*/}
      <ActionButton
        icon={UploadResumeIcon}
        text="Upload Resume"
        className="upload-resume-button"
        onClick={openModal}
      />
      {/*<ActionButton
        text="Tailor Bulk Resumes"
        className="tailor-resumes-button"
      />
      <ActionButton
        text="Tailor Bulk Cover Letters"
        className="tailor-cover-letters-button"
      />*/}
    </div>
  );
};

export default ButtonsSection;
