// useWebSocket.js

import { useEffect } from "react";

const useWebSocket = (url, onCreditUpdate) => {
  useEffect(() => {
    if (!url) {
      //console.error("WebSocket URL is undefined or user is not authenticated.");
      return;
    }

    console.log(`Connecting to WebSocket URL: ${url}`); // Added for debugging
    const socket = new WebSocket(url);

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("WebSocket message received:", data);

        if (
          data.type === "CREDIT_UPDATE" &&
          data.userId &&
          data.newCreditBalance !== undefined
        ) {
          onCreditUpdate(data.newCreditBalance);
        }
        // Handle other message types as needed
      } catch (err) {
        console.error("Error parsing WebSocket message:", err);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  }, [url, onCreditUpdate]);
};

export default useWebSocket;
