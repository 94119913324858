// src/context/CreditContext.js

import React, { createContext, useState, useEffect, useContext } from "react";
import { getUserProfile } from "../api";
import useWebSocket from "../hooks/useWebSocket";
import { toast } from "react-toastify";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom"; // Correct import

export const CreditContext = createContext();

export const CreditProvider = ({ children }) => {
  const [creditBalance, setCreditBalance] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const wsUrl = process.env.REACT_APP_WS_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        //console.log("Fetched user profile:", profile);
        setUserProfile(profile);
        setCreditBalance(profile.credit_balance);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        navigate("/login");
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    }
  }, [isAuthenticated, navigate]);

  const handleWebSocketMessage = (newBalance) => {
    //console.log("Handling WebSocket CREDIT_UPDATE:", newBalance);
    setCreditBalance(newBalance);
  };

  useWebSocket(
    isAuthenticated && userProfile ? wsUrl : null,
    handleWebSocketMessage
  );

  return (
    <CreditContext.Provider value={{ creditBalance, setCreditBalance }}>
      {children}
    </CreditContext.Provider>
  );
};
