import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { jwtDecode } from "jwt-decode";

const AdminRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  const checkAdmin = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    const decoded = jwtDecode(token);
    console.log("Full decoded token:", decoded); // Let's see the full token structure
    return decoded.role === "admin"; // Verify this matches your token's role field name
  };

  const canAccess = isAuthenticated && checkAdmin();
  console.log("Access granted:", canAccess);

  return canAccess ? children : <Navigate to="/dashboard" />;
};

export default AdminRoute;
