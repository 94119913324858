import React, { useState } from "react";
import Modal from "react-modal";
import { useDropzone } from "react-dropzone";
import { uploadResume } from "../api";
import Spinner from "./Spinner";

// Set the app element here if not set in index.js
Modal.setAppElement("#root");

const ResumeUploadModal = ({ isOpen, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
      setIsUploaded(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, .doc, .docx",
    maxFiles: 1,
  });

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    setIsLoading(true);
    setIsUploaded(false);

    try {
      await uploadResume(selectedFile);
      setIsUploaded(true);
    } catch (error) {
      console.error("Error uploading resume:", error);
      alert("Failed to upload resume. Please try again.");
    } finally {
      setIsLoading(false); // Ensure isLoading is false whether the upload succeeds or fails
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Upload Resume"
      className="resume-upload-modal"
      overlayClassName="resume-upload-overlay"
    >
      <h2 className="modal-title">Upload Your Resume</h2>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here...</p>
        ) : (
          <p>Drag & drop your resume here, or click to select a file</p>
        )}
      </div>
      {selectedFile && (
        <p className="selected-file">Selected file: {selectedFile.name}</p>
      )}

      {/* Upload button */}
      <button
        onClick={handleUpload}
        className="action-button upload-button"
        disabled={isLoading} // Disable button while loading
      >
        {isLoading ? <Spinner /> : "Upload Resume"}{" "}
        {/* Spinner shows if loading */}
      </button>

      {/* Cancel button */}
      <button
        onClick={onClose}
        className="action-button cancel-button"
        disabled={isLoading} // Disable cancel button while loading
      >
        Cancel
      </button>

      {/* Success message */}
      {isUploaded && (
        <p className="success-message">Resume uploaded successfully!</p>
      )}
    </Modal>
  );
};

export default ResumeUploadModal;
