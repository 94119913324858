import React, { useEffect, useState } from "react";
import { getAllUsers } from "../api"; // Ensure this API function is implemented correctly
import "../AdminDashboard.css";

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const LIMIT = 25;

  useEffect(() => {
    // Fetch users when component mounts
    const fetchUsers = async () => {
      try {
        const usersData = await getAllUsers();
        setUsers(usersData);
        setFilteredUsers(usersData); // Initialize filtered users
      } catch (err) {
        console.error("Error fetching users:", err);
      }
    };

    fetchUsers();
  }, []);

  // Filtering logic for search
  useEffect(() => {
    const filtered = users.filter((user) =>
      `${user.first_name} ${user.last_name} ${user.email} ${user.role}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  }, [searchTerm, users]);

  // Sorting logic
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setFilteredUsers(sortedUsers);
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredUsers.length / LIMIT);
  const currentPageData = filteredUsers.slice(
    (currentPage - 1) * LIMIT,
    currentPage * LIMIT
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="panel">
      <h3>Users</h3>
      <div className="toolbar">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
      </div>
      <div className="content-section user-table-container">
        {filteredUsers.length > 0 ? (
          <table className="user-table">
            <thead>
              <tr>
                <th onClick={() => requestSort("first_name")}>
                  First Name{" "}
                  {sortConfig.key === "first_name"
                    ? sortConfig.direction === "ascending"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th onClick={() => requestSort("last_name")}>
                  Last Name{" "}
                  {sortConfig.key === "last_name"
                    ? sortConfig.direction === "ascending"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th onClick={() => requestSort("email")}>
                  Email{" "}
                  {sortConfig.key === "email"
                    ? sortConfig.direction === "ascending"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th onClick={() => requestSort("role")}>
                  Role{" "}
                  {sortConfig.key === "role"
                    ? sortConfig.direction === "ascending"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th onClick={() => requestSort("phone_number")}>
                  Phone Number{" "}
                  {sortConfig.key === "phone_number"
                    ? sortConfig.direction === "ascending"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th onClick={() => requestSort("credit_balance")}>
                  Credit Balance{" "}
                  {sortConfig.key === "credit_balance"
                    ? sortConfig.direction === "ascending"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th onClick={() => requestSort("subscription_tier")}>
                  Subscription Tier{" "}
                  {sortConfig.key === "subscription_tier"
                    ? sortConfig.direction === "ascending"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((user) => (
                <tr key={user.USER_ID}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.phone_number}</td>
                  <td>{user.credit_balance}</td>
                  <td>{user.subscription_tier}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No users found.</p>
        )}
      </div>
      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default UsersTable;
