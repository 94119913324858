// src/components/PurchaseCredits.js

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PackagePricingBox from "./PackagePricingBox";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PurchaseCredits = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const packages = [
    { credits: 20, price: "$5" },
    { credits: 30, price: "$7" },
    { credits: 40, price: "$10" },
  ];

  const handlePurchase = async () => {
    if (!selectedPackage) {
      toast.error("Please select a credit package.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("You must be logged in to purchase credits.");
        return;
      }

      setIsProcessing(true);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `http://localhost:5000/api/payments/create-checkout-session`,
        { creditsToPurchase: selectedPackage.credits },
        config
      );

      const { sessionId } = response.data;
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        toast.error("Failed to redirect to payment. Please try again.");
      }
    } catch (error) {
      console.error("Error purchasing credits:", error);
      toast.error("Failed to purchase credits. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="dashboard-container">
      <h2>Purchase Credits</h2>
      <div className="dashboard-grid">
        {packages.map((pkg) => (
          <PackagePricingBox
            key={pkg.credits}
            credits={pkg.credits}
            price={pkg.price}
            isSelected={selectedPackage?.credits === pkg.credits}
            onSelect={(credits, price) =>
              setSelectedPackage({ credits, price })
            }
          />
        ))}
      </div>
      <button
        onClick={handlePurchase}
        className="action-button"
        disabled={isProcessing}
      >
        {isProcessing ? "Processing..." : "Purchase"}
      </button>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
};

export default PurchaseCredits;
