// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";
import { login, signup } from "../api"; // Import the API functions
import { jwtDecode } from "jwt-decode"; // Correct default import

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      try {
        //console.log("Stored Token:", storedToken); // Log the stored token
        const decoded = jwtDecode(storedToken); // Decode the token
        //console.log("Decoded Token:", decoded); // Log the decoded token
        setUser(decoded);
      } catch (error) {
        console.error("Error decoding stored token:", error);
        setUser(null);
        localStorage.removeItem("token"); // Remove invalid token
      }
    }
    setIsLoading(false); // Set loading to false after checking token
  }, []);

  /**
   * Handle user login
   * @param {Object} param0 - An object containing email and password
   * @returns {Object} - An object containing the token or an error message
   */
  const handleLogin = async ({ email, password }) => {
    try {
      const response = await login({ email, password });
      const { token, user: userData } = response;
      // Save token and user data to localStorage or state
      localStorage.setItem("token", token);
      setUser(userData);
    } catch (error) {
      // Re-throw the error to be caught in Login.js
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Login failed. Please try again.");
      }
    }
  };

  /**
   * Handle user signup
   * @param {Object} userData - An object containing user registration data
   * @returns {Object} - An object containing the token or an error message
   */
  const handleSignup = async (userData) => {
    try {
      const response = await signup(userData); // Use the signup function from api.js
      const { token } = response; // Correct destructuring

      if (token) {
        localStorage.setItem("token", token);
        const decoded = jwtDecode(token); // Decode the token
        setUser(decoded);
        return { token };
      } else {
        return { message: "Signup failed. Please try again." };
      }
    } catch (error) {
      console.error("Signup error:", error);
      return { message: error.message || "Signup failed." };
    }
  };

  // Just a comment to trigger Git for deployment purposes.

  /**
   * Handle user logout
   */
  const logout = () => {
    setUser(null);
    localStorage.removeItem("token");
    window.location.href = "/login"; // Redirect to login page
  };

  // Determine if the user is authenticated based on the presence of the user object
  const isAuthenticated = !!user;

  //console.log("AuthContext - user:", user);
  //console.log("AuthContext - isAuthenticated:", isAuthenticated);

  useEffect(() => {
    const checkTokenValidity = () => {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        try {
          const decoded = jwtDecode(storedToken);
          const isExpired = decoded.exp * 1000 < Date.now();
          if (isExpired) {
            console.warn("Token expired, logging out user.");
            logout();
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          logout();
        }
      }
    };

    const interval = setInterval(checkTokenValidity, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(interval); // Cleanup on unmount
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        user,
        handleLogin,
        handleSignup,
        logout,
        isAuthenticated,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
