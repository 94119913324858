import React from "react";
import MetricPanel from "./MetricPanel"; // Import the reusable metric panel
import ProgressTracker from "./ProgressTracker"; // Import the progress tracker component
import jobIcon from "../assets/job-icon.svg"; // Replace with the correct path
import resumeIcon from "../assets/resume-icon.svg";
import coverLetterIcon from "../assets/cover-letter-icon.svg";
import activityIcon from "../assets/activity-icon.svg";

const Panels = ({
  jobsCount,
  resumeCount,
  coverLetterCount,
  recentActivitiesCount,
}) => {
  return (
    <div className="panels-container">
      <div className="dashboard-grid">
        {/* First two columns with 4 panels */}
        <MetricPanel
          icon={jobIcon}
          title="Jobs added"
          value={jobsCount}
          change={2} // Placeholder, replace this with dynamic value if needed
        />
        <MetricPanel
          icon={resumeIcon}
          title="Tailored resumes generated"
          value={resumeCount}
          change={5} // Placeholder, replace this with dynamic value if needed
        />
        <MetricPanel
          icon={coverLetterIcon}
          title="Cover letters created"
          value={coverLetterCount}
          change={1.3}
        />
        <MetricPanel
          icon={activityIcon}
          title="Recent activities"
          value={recentActivitiesCount}
          change={8.2}
        />

        {/* Third column, spanning two rows for the pie chart */}
        <div className="progress-section">
          <ProgressTracker
            jobsCount={jobsCount}
            coverLetterCount={coverLetterCount}
          />
        </div>
      </div>
    </div>
  );
};

export default Panels;
