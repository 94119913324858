// Spinner.js
import React from "react";

const Spinner = () => (
  <div className="spinner">
    {/* You can style or animate this div to represent a loading spinner */}
  </div>
);

export default Spinner;
