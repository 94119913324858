import React, { useState } from "react";
import PropTypes from "prop-types";
import "../Dashboard.css";

const BulkActionsDropdown = ({ handleBulkAction }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="bulk-actions-dropdown active">
      <button
        className="action-button dropdown-toggle"
        onClick={toggleDropdown}
      >
        Bulk Actions
      </button>
      {isDropdownOpen && (
        <ul className="dropdown-menu">
          <li
            onClick={() => {
              handleBulkAction("tailorResume");
              setIsDropdownOpen(false);
            }}
            className="dropdown-item"
          >
            Tailor Resumes
          </li>
          <li
            onClick={() => {
              handleBulkAction("tailorCoverLetter");
              setIsDropdownOpen(false);
            }}
            className="dropdown-item"
          >
            Tailor Cover Letters
          </li>
          <li
            onClick={() => {
              handleBulkAction("delete");
              setIsDropdownOpen(false);
            }}
            className="dropdown-item delete-item"
          >
            Delete
          </li>
        </ul>
      )}
    </div>
  );
};

BulkActionsDropdown.propTypes = {
  handleBulkAction: PropTypes.func.isRequired,
};

export default BulkActionsDropdown;
