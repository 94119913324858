// src/components/UserCreditManager.js

import React, { useEffect, useState } from "react";
import { getAllUsers, addUserCredits } from "../api"; // Updated to use addUserCredits
import "../AdminDashboard.css";

const UserCreditManager = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [creditChanges, setCreditChanges] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const LIMIT = 25;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getAllUsers();
        setUsers(usersData);
        setFilteredUsers(usersData);
      } catch (err) {
        console.error("Error fetching users:", err);
      }
    };

    fetchUsers();
  }, []);

  // Handle search filtering
  useEffect(() => {
    const filtered = users.filter((user) =>
      `${user.first_name} ${user.last_name} ${user.email} ${user.role}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  }, [searchTerm, users]);

  // Handle sorting
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setFilteredUsers(sortedUsers);
  };

  // Pagination
  const totalPages = Math.ceil(filteredUsers.length / LIMIT);
  const currentPageData = filteredUsers.slice(
    (currentPage - 1) * LIMIT,
    currentPage * LIMIT
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle credit input changes
  const handleCreditChange = (userId, value) => {
    setCreditChanges((prev) => ({ ...prev, [userId]: value }));
  };

  // In UserCreditManager.js
  const handleUpdateCredit = async (userId) => {
    const newCreditAmount = creditChanges[userId];
    if (newCreditAmount === undefined || newCreditAmount === "") {
      return alert("Please enter a credit amount.");
    }

    try {
      await addUserCredits(userId, parseInt(newCreditAmount, 10));
      alert("User credit updated successfully.");
      setCreditChanges((prev) => ({ ...prev, [userId]: "" }));
      // Refresh user data to reflect changes
      const updatedUsers = await getAllUsers();
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
    } catch (error) {
      console.error("Error updating user credit:", error);
      alert("Failed to update user credit.");
    }
  };

  return (
    <div className="panel">
      <h3>User Credit Manager</h3>
      <div className="toolbar">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
      </div>
      <div className="content-section user-table-container">
        {filteredUsers.length > 0 ? (
          <table className="user-table">
            <thead>
              <tr>
                <th onClick={() => requestSort("first_name")}>First Name</th>
                <th onClick={() => requestSort("last_name")}>Last Name</th>
                <th onClick={() => requestSort("email")}>Email</th>
                <th onClick={() => requestSort("credit_balance")}>
                  Credit Balance
                </th>
                <th>Update Credit</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((user) => (
                <tr key={user.USER_ID}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>{user.credit_balance}</td>
                  <td>
                    <input
                      type="number"
                      value={creditChanges[user.USER_ID] || ""}
                      onChange={(e) =>
                        handleCreditChange(user.USER_ID, e.target.value)
                      }
                      placeholder="Enter amount"
                    />
                    <button onClick={() => handleUpdateCredit(user.USER_ID)}>
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No users found.</p>
        )}
      </div>
      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default UserCreditManager;
