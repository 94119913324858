import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles.css";
import WaveBackground from "../components/WaveBackground";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faPhone,
  faUniversity,
  faBuilding,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import signupBanner from "../assets/Dashboard.png";
import Breadcrumb from "../components/BreadCrumb";

// get API_URL  from environment variables
import { API_URL } from "../api"; // Adjust the path as necessary

const Signup = () => {
  const [currentStage, setCurrentStage] = useState("signup");
  // Personal Information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [school, setSchool] = useState("");

  // Job Interests
  const [jobInterests, setJobInterests] = useState([]);
  const [currentJobInterest, setCurrentJobInterest] = useState("");

  // Experience
  const [experienceYears, setExperienceYears] = useState("");
  const [researchExperience, setResearchExperience] = useState("");
  const [resumeFile, setResumeFile] = useState(null);

  // Published Paper
  const [paperTitle, setPaperTitle] = useState("");
  const [journalName, setJournalName] = useState("");
  const [publicationDate, setPublicationDate] = useState("");
  const [paperLink, setPaperLink] = useState("");

  // Interests
  const [interests, setInterests] = useState([]);
  const [currentInterestInput, setCurrentInterestInput] = useState("");

  // Privacy Policy Checkbox
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  // Other States
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Validation Functions
  const validatePassword = (password) => {
    let message = "";
    if (password.length < 16) {
      message = "Password must be at least 16 characters long.";
    } else if (!/[!@#$%^&*]/.test(password)) {
      message = "Password must include at least one special character.";
    } else if (!/[0-9]/.test(password)) {
      message = "Password must include at least one digit.";
    }
    setPasswordValidationMsg(message);
    return message === "";
  };

  const validatePhoneNumber = (phoneNumber) => {
    const usCanadaPhoneRegex = /^(?:\+1)?\d{10}$/; // Allows +1 or no country code, followed by 10 digits
    return usCanadaPhoneRegex.test(phoneNumber);
  };

  const validateLinkedInURL = (url) => {
    const linkedInRegex = /^(https?:\/\/)?([\w]+\.)?linkedin\.com\/.*$/i;
    return linkedInRegex.test(url);
  };

  // Job Interests Functions
  const handleAddJobInterest = (e) => {
    if ((e.key === "," || e.key === "Enter") && currentJobInterest.trim()) {
      e.preventDefault();
      if (!jobInterests.includes(currentJobInterest.trim())) {
        setJobInterests([...jobInterests, currentJobInterest.trim()]);
      }
      setCurrentJobInterest("");
    }
  };

  const handleRemoveJobInterest = (interestToRemove) => {
    setJobInterests(
      jobInterests.filter((interest) => interest !== interestToRemove)
    );
  };

  // Interests Functions
  const handleAddInterest = (e) => {
    if ((e.key === "," || e.key === "Enter") && currentInterestInput.trim()) {
      e.preventDefault();
      if (!interests.includes(currentInterestInput.trim())) {
        setInterests([...interests, currentInterestInput.trim()]);
      }
      setCurrentInterestInput("");
    }
  };

  const handleRemoveInterest = (interestToRemove) => {
    setInterests(interests.filter((interest) => interest !== interestToRemove));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Password Validation
    if (!validatePassword(password)) {
      setMessage(
        "Password must be at least 16 characters long and include at least one special character and one digit."
      );
      setIsError(true);
      setLoading(false);
      return;
    }

    // Phone Number Validation
    if (!validatePhoneNumber(phoneNumber)) {
      setMessage(
        "Invalid phone number format. Please enter a valid US/Canada number."
      );
      setIsError(true);
      setLoading(false);
      return;
    }

    // LinkedIn URL Validation
    if (!validateLinkedInURL(linkedinURL)) {
      setMessage("Please enter a valid LinkedIn profile URL.");
      setIsError(true);
      setLoading(false);
      return;
    }

    // Interests Validation
    if (interests.length < 3) {
      setMessage("Please enter at least 3 interests.");
      setIsError(true);
      setLoading(false);
      return;
    }

    // Create brief_summary
    let briefSummary = `LinkedIn Profile URL: ${linkedinURL}\n`;
    briefSummary += `Experience Years: ${experienceYears}\n`;
    if (researchExperience) {
      briefSummary += `Research Experience: ${researchExperience}\n`;
    }
    if (paperTitle || journalName || publicationDate || paperLink) {
      briefSummary += `Published Paper:\n`;
      if (paperTitle) briefSummary += ` - Title: ${paperTitle}\n`;
      if (journalName) briefSummary += ` - Journal: ${journalName}\n`;
      if (publicationDate)
        briefSummary += ` - Publication Date: ${publicationDate}\n`;
      if (paperLink) briefSummary += ` - Link: ${paperLink}\n`;
    }
    if (interests.length > 0) {
      briefSummary += `Interests: ${interests.join(", ")}\n`;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phone_number", phoneNumber);
    formData.append("linkedin_url", linkedinURL);
    formData.append("school", school);
    jobInterests.forEach((interest) =>
      formData.append("job_interests", interest)
    );
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("brief_summary", briefSummary);
    formData.append("resume", resumeFile);

    // **Add this to log the FormData entries**
    for (let [key, value] of formData.entries()) {
      //console.log(`${key}: ${value}`);
    }

    // Privacy Policy Validation
    if (!privacyPolicyAccepted) {
      setMessage("You must accept the Privacy Policy to proceed.");
      setIsError(true);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/auth/signup`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setMessage(
          "Signup successful! Please check your email to verify your account."
        );
        setIsError(false);
        setTimeout(() => navigate("/login"), 2000);
      } else {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const errorData = await response.json();
          setMessage(errorData.message || "Signup failed. Please try again.");
        } else {
          const errorText = await response.text();
          console.error("Received non-JSON response:", errorText);
          setMessage("An unexpected error occurred. Please try again later.");
        }
        setIsError(true);
      }
    } catch (error) {
      console.error("An error occurred during signup:", error);
      setMessage("An error occurred. Please try again later.");
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const universities = [
    "Princeton University",
    "Massachusetts Institute of Technology (MIT)",
    "Harvard University",
    "Stanford University",
    "Yale University",
    "University of Chicago",
    "Johns Hopkins University",
    "University of Pennsylvania",
    "California Institute of Technology (Caltech)",
    "Duke University",
    "Northwestern University",
    "Dartmouth College",
    "Brown University",
    "Vanderbilt University",
    "Rice University",
    "Washington University in St. Louis",
    "Cornell University",
    "University of Notre Dame",
    "Columbia University",
    "University of California, Berkeley",
    "Emory University",
    "University of California, Los Angeles (UCLA)",
    "University of Southern California",
    "Carnegie Mellon University",
    "University of Michigan, Ann Arbor",
    "Wake Forest University",
    "University of Virginia",
    "New York University (NYU)",
    "University of Florida",
    "University of North Carolina at Chapel Hill",
    "Tufts University",
    "Boston College",
    "University of Rochester",
    "University of California, Santa Barbara",
    "University of California, Irvine",
    "University of California, San Diego",
    "University of Wisconsin-Madison",
    "University of Texas at Austin",
    "University of Washington",
    "University of Miami",
    "Pepperdine University",
    "Georgia Institute of Technology",
    "Lehigh University",
    "University of Illinois Urbana-Champaign",
    "Pennsylvania State University",
    "University of Connecticut",
    "University of Georgia",
    "Purdue University",
    "Ohio State University",
    "Syracuse University",
    "Rutgers University-New Brunswick",
    "University of Maryland, College Park",
    "Southern Methodist University",
    "Clemson University",
    "American University",
    "Baylor University",
    "Texas A&M University",
    "Michigan State University",
    "Clark University",
    "Worcester Polytechnic Institute",
    "University of Pittsburgh",
    "University of Minnesota Twin Cities",
    "Indiana University Bloomington",
    "Stevens Institute of Technology",
    "Marquette University",
    "University of Denver",
    "University of Delaware",
    "University of Tulsa",
    "Virginia Tech",
    "University of Colorado Boulder",
    "Texas Christian University",
    "University of Massachusetts Amherst",
    "University of Vermont",
    "University of San Diego",
    "University of Arizona",
    "University of Iowa",
    "University of Tennessee",
    "University of Kansas",
    "University of San Francisco",
    "University of Alabama",
    "University of Oklahoma",
    "University of Missouri",
    "University of Oregon",
    "University of Utah",
    "University of Nebraska-Lincoln",
    "University of New Hampshire",
    "University of South Carolina",
    "Auburn University",
    "Howard University",
    "University of the Pacific",
    "University of Kentucky",
    "Florida State University",
    "University of Rhode Island",
    "Hendrix College",
    "Swarthmore College",
    "Williams College",
    "Pomona College",
    "Amherst College",
    "Bowdoin College",
    "Wellesley College",
    "Claremont McKenna College",
    "Middlebury College",
    "Carleton College",
    "Davidson College",
    "Washington and Lee University",
    "Hamilton College",
    "Haverford College",
    "Colgate University",
    "Wesleyan University",
    "Grinnell College",
    "Vassar College",
    "Bates College",
    "Colby College",
    "Harvey Mudd College",
    "United States Military Academy",
    "Barnard College",
    "Oberlin College",
    "Colorado College",
    "Smith College",
    "University of Richmond",
    "Bryn Mawr College",
    "Scripps College",
    "Kenyon College",
    "Macalester College",
    "Mount Holyoke College",
    "Bucknell University",
    "Trinity College",
    "Skidmore College",
    "Lafayette College",
    "Occidental College",
    "Sewanee—University of the South",
    "Pitzer College",
    "Gettysburg College",
    "Bard College",
    "Franklin and Marshall College",
    "Connecticut College",
    "Whitman College",
    "Wheaton College (IL)",
    "Union College (NY)",
    "Dickinson College",
    "DePauw University",
    "Furman University",
    "Centre College",
    "Denison University",
    "St. Olaf College",
    "St. Lawrence University",
    "Muhlenberg College",
    "Rhodes College",
    "Sarah Lawrence College",
    "Hobart and William Smith Colleges",
    "Wheaton College (MA)",
    "Willamette University",
    "Gustavus Adolphus College",
    "Lawrence University",
    "Spelman College",
    "Birmingham-Southern College",
    "Augustana College",
    "Knox College",
    "Hampden-Sydney College",
    "St. John's College",
    "Ripon College",
    "Goucher College",
    "Hollins University",
    "Randolph-Macon College",
    "Juniata College",
    "Kalamazoo College",
    "Earlham College",
    "Cornell College",
    "Coastal Carolina University",
    "Southwestern University",
    "Wabash College",
    "Hanover College",
    "Transylvania University",
    "Westmont College",
    "Bennington College",
    "Lake Forest College",
    "Illinois Wesleyan University",
    "Millsaps College",
    "University of Puget Sound",
  ];

  // Handle "Continue" button
  const handleContinue = () => {
    if (currentStage === "signup") {
      // Validate required fields in signup
      if (
        !firstName ||
        !lastName ||
        !email ||
        !password ||
        !phoneNumber ||
        !linkedinURL ||
        !school
      ) {
        setMessage("Please fill in all required fields.");
        setIsError(true);
        return;
      }
      // Validate that at least 3 job interests are entered
      if (jobInterests.length < 3) {
        setMessage("Please enter at least 3 job interests.");
        setIsError(true);
        return;
      }
      // Clear message and proceed
      setMessage("");
      setIsError(false);
      setCurrentStage("experience");
    } else if (currentStage === "experience") {
      // Validate required fields in experience
      if (!experienceYears) {
        setMessage("Please select your years of experience.");
        setIsError(true);
        return;
      }
      if (!resumeFile) {
        setMessage("Please upload your resume.");
        setIsError(true);
        return;
      }
      // Clear message and proceed
      setMessage("");
      setIsError(false);
      setCurrentStage("publishedPaper");
    } else if (currentStage === "publishedPaper") {
      setCurrentStage("interests");
    }
  };

  // Handle "Back" button
  const handleBack = () => {
    if (currentStage === "experience") {
      setCurrentStage("signup");
    } else if (currentStage === "publishedPaper") {
      setCurrentStage("experience");
    } else if (currentStage === "interests") {
      setCurrentStage("publishedPaper");
    }
  };

  return (
    <div className="signup-page">
      {/* Show the loading overlay when loading is true */}
      {loading && (
        <div className="custom-loading-overlay">
          <div className="custom-loading-spinner"></div>
        </div>
      )}
      <div className="left-panel">
        {/* Breadcrumb Navigation */}
        <Breadcrumb currentStage={currentStage} />

        {/* Sign Up Section */}
        {currentStage === "signup" && (
          <form className="signup-form">
            <h2 className="signup-title">Sign Up</h2>
            {message && (
              <div className={`message ${isError ? "error" : "success"}`}>
                {message}
              </div>
            )}
            <div className="input-row">
              <div className="input-group">
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                required
              />
              {passwordValidationMsg && (
                <small className="validation-message">
                  {passwordValidationMsg}
                </small>
              )}
            </div>
            <div className="input-group">
              <label>Phone Number</label>
              <input
                type="tel"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label>LinkedIn Profile URL</label>
              <input
                type="url"
                placeholder="Enter LinkedIn profile URL"
                value={linkedinURL}
                onChange={(e) => setLinkedinURL(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label>School</label>
              <select
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                required
              >
                <option value="">Select your school</option>
                {universities.map((uni) => (
                  <option key={uni} value={uni}>
                    {uni}
                  </option>
                ))}
              </select>
            </div>
            {/* Job Interests */}
            <div className="input-group">
              <label>
                Job Interests
                <span
                  className="tooltip"
                  data-tooltip="Add at least 3 job interests. Press Enter or comma to add each."
                >
                  ℹ️
                </span>
              </label>
              <input
                type="text"
                placeholder="Add job interests"
                value={currentJobInterest}
                onChange={(e) => setCurrentJobInterest(e.target.value)}
                onKeyDown={handleAddJobInterest}
              />
              <div className="job-interests">
                {jobInterests.map((interest, index) => (
                  <span
                    key={index}
                    className="job-interest-tag"
                    onClick={() => handleRemoveJobInterest(interest)}
                  >
                    {interest} &times;
                  </span>
                ))}
              </div>
            </div>
            <button
              type="button"
              className="signup-button"
              onClick={handleContinue}
            >
              Continue
            </button>
          </form>
        )}

        {/* Experience Section */}
        {currentStage === "experience" && (
          <div className="experience-section">
            <h2 className="signup-title">Experience</h2>
            {message && (
              <div className={`message ${isError ? "error" : "success"}`}>
                {message}
              </div>
            )}
            <p>How many years of work experience do you have?</p>
            <div className="experience-options">
              {["0-1", "1-2", "2-3", "3-5", "5+"].map((exp) => (
                <button
                  key={exp}
                  className={`radio-button ${
                    experienceYears === exp ? "selected" : ""
                  }`}
                  onClick={() => setExperienceYears(exp)}
                >
                  {exp}
                </button>
              ))}
            </div>
            {/* Resume Upload */}
            <div className="input-group">
              <label>Upload Your Resume</label>
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file && file.size > 5 * 1024 * 1024) {
                    // 5 MB limit
                    setMessage("File size should not exceed 5 MB.");
                    setIsError(true);
                    setResumeFile(null);
                  } else {
                    setResumeFile(file);
                    setMessage("");
                    setIsError(false);
                  }
                }}
                required
              />
              {resumeFile && <p>Selected file: {resumeFile.name}</p>}
            </div>

            {/* Research Experience */}
            <div className="input-group">
              <label>Do you have any research experience?</label>
              <textarea
                placeholder="Tell us about your research experience (optional)"
                value={researchExperience}
                onChange={(e) => setResearchExperience(e.target.value)}
              />
            </div>
            <div className="navigation-buttons">
              <button
                type="button"
                className="back-button"
                onClick={handleBack}
              >
                Back
              </button>
              <button
                type="button"
                className="continue-button"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {/* Published Paper Section */}
        {currentStage === "publishedPaper" && (
          <div className="published-paper-section">
            <h2 className="signup-title">Published Paper (Optional)</h2>
            <div className="input-group">
              <label>Paper Title</label>
              <input
                type="text"
                placeholder="Enter paper title"
                value={paperTitle}
                onChange={(e) => setPaperTitle(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Journal Name</label>
              <input
                type="text"
                placeholder="Enter journal name"
                value={journalName}
                onChange={(e) => setJournalName(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Publication Date</label>
              <input
                type="date"
                placeholder="Enter publication date"
                value={publicationDate}
                onChange={(e) => setPublicationDate(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Paper Link</label>
              <input
                type="url"
                placeholder="Enter paper URL"
                value={paperLink}
                onChange={(e) => setPaperLink(e.target.value)}
              />
            </div>
            <div className="navigation-buttons">
              <button
                type="button"
                className="back-button"
                onClick={handleBack}
              >
                Back
              </button>
              <button
                type="button"
                className="continue-button"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {/* Interests Section */}
        {currentStage === "interests" && (
          <div className="interests-section">
            <h2 className="signup-title">Interests</h2>
            {message && (
              <div className={`message ${isError ? "error" : "success"}`}>
                {message}
              </div>
            )}
            <p className="hobbies-interests">
              What are your hobbies and interests?
            </p>
            <div className="input-group">
              <label>Interests</label>
              <input
                type="text"
                placeholder="Add new"
                value={currentInterestInput}
                onChange={(e) => setCurrentInterestInput(e.target.value)}
                onKeyDown={handleAddInterest}
                required
              />
              <div className="interests-tags">
                {interests.map((interest, index) => (
                  <span
                    key={index}
                    className="interest-tag"
                    onClick={() => handleRemoveInterest(interest)}
                  >
                    {interest} &times;
                  </span>
                ))}
              </div>
            </div>
            <br />
            <br />
            <div className="privacy-policy-checkbox">
              <input
                type="checkbox"
                id="privacyPolicy"
                checked={privacyPolicyAccepted}
                onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
              />
              <label htmlFor="privacyPolicy">
                I agree to the{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </label>
            </div>

            <div className="navigation-buttons">
              <button
                type="button"
                className="back-button"
                onClick={handleBack}
              >
                Back
              </button>
              <button
                type="button"
                className="continue-button"
                onClick={handleSignup}
              >
                Sign Up
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="right-panel">
        <div className="dashboard-mockup">
          <img src={signupBanner} alt="signup-banner" />
        </div>
      </div>
    </div>
  );
};

export default Signup;
