import React from "react";
import PropTypes from "prop-types";
import editIcon from "../assets/edit-01.svg";
import "../Dashboard.css";

const EditJobButton = ({ onClick, disabled }) => {
  return (
    <button
      className={`edit-job-button ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={editIcon} alt="Edit Job" />
    </button>
  );
};

export default EditJobButton;
