import React, { useState } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import DownloadButton from "../components/DownloadButton";
import TailorButton from "../components/TailorButton";
import JobLinkButton from "../components/JobLinkButton";
import EditJobButton from "../components/EditJobButton";
import DeleteJobButton from "../components/DeleteJobButton";
import SaveJobButton from "../components/SaveJobButton";
import CancelButton from "./CancelButton";

const JobsTable = ({
  jobs,
  selectedJobs,
  setSelectedJobs,
  handleCheckboxChange,
  handleInputChange,
  handleTailor,
  handleSaveJob,
  openDeleteModal,
  handleAddRow,
  setJobs,
}) => {
  const [validationErrors, setValidationErrors] = useState([]);

  const validateJobFields = (job) => {
    const errors = {};

    // Company name validation
    if (!job.company || job.company.length < 2 || job.company.length > 100) {
      errors.company = "Company name must be between 2 and 100 characters.";
    }

    // Job title validation
    if (!job.jobTitle || job.jobTitle.length < 2 || job.jobTitle.length > 100) {
      errors.jobTitle = "Job title must be between 2 and 100 characters.";
    }

    // Job link validation
    if (!job.jobLink) {
      errors.jobLink = "Job link is required.";
    } else if (!/^https?:\/\/.+/i.test(job.jobLink)) {
      errors.jobLink =
        "Job link must be a valid URL starting with http:// or https://.";
    }

    return errors;
  };

  const isValidJobUrl = (url) => {
    const urlPattern =
      /^(https?:\/\/)?(([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.?)+[a-zA-Z]{2,}|localhost(:\d+)?(\/[-a-zA-Z\d%@_.~+&:]*)*(\?[;&a-zA-Z\d%@_.,~+&:=-]*)?(\#[-a-zA-Z\d_]*)?$/i;

    const jobUrlPattern =
      /(careers|jobs|job-openings|job-listing|apply|CandidateExperience|recruitment|job|OpportunityDetail|job-description|career|talent|open-roles|join|position|work-here|grnh|breezy|myapps|track|pastebin)/i;

    return urlPattern.test(url) && jobUrlPattern.test(url);
  };

  const handleEditJob = (index) => {
    const updatedJobs = [...jobs];
    updatedJobs[index].isEditing = true;
    setJobs(updatedJobs);
  };

  const handleSaveJobWithValidation = (index) => {
    const job = jobs[index];
    const errors = validateJobFields(job);

    if (Object.keys(errors).length > 0) {
      // If there are validation errors, set them in the state
      const updatedErrors = [...validationErrors];
      updatedErrors[index] = errors;
      setValidationErrors(updatedErrors);
    } else {
      // If no validation errors, save the job
      handleSaveJob(index);
      const updatedErrors = [...validationErrors];
      updatedErrors[index] = {}; // Clear errors for this job
      setValidationErrors(updatedErrors);
    }
  };

  const handleCancelEdit = (index) => {
    const updatedJobs = [...jobs];

    // If the job has no ID (i.e., it's a newly added job), remove it
    if (updatedJobs[index].jobId === null) {
      updatedJobs.splice(index, 1);
    } else {
      // Otherwise, just cancel editing
      updatedJobs[index].isEditing = false;
    }

    setJobs(updatedJobs);
    // Clear validation errors for this job
    const updatedErrors = [...validationErrors];
    updatedErrors[index] = {};
    setValidationErrors(updatedErrors);
  };

  // Handle selecting/deselecting all jobs
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allJobIds = jobs.map((job) => job.jobId);
      setSelectedJobs(allJobIds);
    } else {
      setSelectedJobs([]);
    }
  };

  return (
    <table className="jobs-table">
      <thead>
        <tr>
          <th className="checkbox-column">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                className="select-all-checkbox"
                onChange={handleSelectAll}
                checked={selectedJobs.length === jobs.length && jobs.length > 0}
              />
            </div>
          </th>
          <th>Company</th>
          <th>Job Title</th>
          <th>Job Link</th>
          <th>Resume</th>
          <th>Cover Letter</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job, index) => (
          <tr key={index}>
            <td>
              <input
                type="checkbox"
                className="job-checkbox"
                checked={selectedJobs.includes(job.jobId)}
                onChange={() => handleCheckboxChange(job.jobId)}
              />
            </td>
            <td>
              <div className="input-wrapper">
                <input
                  type="text"
                  value={job.company}
                  onChange={(e) =>
                    handleInputChange(index, "company", e.target.value)
                  }
                  className="input-field"
                  placeholder="Enter company"
                  disabled={!job.isEditing || job.loading}
                />
                {validationErrors[index]?.company && (
                  <div className="error-message">
                    {validationErrors[index].company}
                  </div>
                )}
              </div>
            </td>

            <td>
              <div className="input-wrapper">
                <input
                  type="text"
                  value={job.jobTitle}
                  onChange={(e) =>
                    handleInputChange(index, "jobTitle", e.target.value)
                  }
                  className="input-field"
                  placeholder="Enter job title"
                  disabled={!job.isEditing || job.loading}
                />
                {validationErrors[index]?.jobTitle && (
                  <div className="error-message">
                    {validationErrors[index].jobTitle}
                  </div>
                )}
              </div>
            </td>

            <td>
              <div className="input-wrapper">
                <JobLinkButton
                  jobLink={job.jobLink}
                  isEditing={job.isEditing}
                  onChange={(e) =>
                    handleInputChange(index, "jobLink", e.target.value)
                  }
                  errorMessage={validationErrors[index]?.jobLink}
                  disabled={!job.isEditing || job.loading}
                />
              </div>
            </td>

            {/* Resume Field */}
            <td>
              {job.loadingResume ? (
                <LoadingSpinner />
              ) : job.resumeTailored ? (
                <>
                  <DownloadButton link={job.resumeLink} text="Resume" />
                </>
              ) : (
                <TailorButton
                  onClick={() => handleTailor(index, "resume")}
                  text="Tailor"
                />
              )}
            </td>

            {/* Cover Letter Field */}
            <td>
              {job.loadingCoverLetter ? (
                <LoadingSpinner />
              ) : job.coverLetterTailored ? (
                <>
                  <DownloadButton
                    link={job.coverLetterLink}
                    text="Cover Letter"
                  />
                </>
              ) : (
                <TailorButton
                  onClick={() => handleTailor(index, "coverLetter")}
                  text="Tailor"
                />
              )}
            </td>

            {/* Edit/Delete/Save/Cancel Buttons */}
            <td>
              {job.loading ? (
                <LoadingSpinner />
              ) : job.isEditing ? (
                <div className="action-buttons-container">
                  <SaveJobButton
                    onClick={() => handleSaveJobWithValidation(index)}
                  />
                  <CancelButton onClick={() => handleCancelEdit(index)} />
                </div>
              ) : (
                <div className="action-buttons-container">
                  <DeleteJobButton onClick={() => openDeleteModal(index)} />
                  {/* Edit button is only shown if neither resume nor cover letter is tailored */}
                  {!job.resumeTailored && !job.coverLetterTailored && (
                    <EditJobButton onClick={() => handleEditJob(index)} />
                  )}
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default JobsTable;
