// ... other imports
import React from "react";
import "../styles.css"; // We'll create this CSS file

const Breadcrumb = ({ currentStage }) => {
  const stages = [
    { label: "Sign Up", key: "signup", number: 1 },
    { label: "Experience", key: "experience", number: 2 },
    { label: "Published Paper", key: "publishedPaper", number: 3 },
    { label: "Interests", key: "interests", number: 4 },
  ];

  return (
    <div className="breadcrumb">
      {stages.map((stage, index) => (
        <React.Fragment key={stage.key}>
          <div
            className={`breadcrumb-step ${
              currentStage === stage.key ? "active" : ""
            } ${
              index < stages.findIndex((s) => s.key === currentStage)
                ? "completed"
                : ""
            }`}
          >
            <div className="ellipse">
              <span className="step-number">{stage.number}</span>
            </div>
            <span className="step-label">{stage.label}</span>
          </div>
          {index < stages.length - 1 && (
            <div className="breadcrumb-arrow">
              {/* SVG Arrow */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10 17.5L15 12.5L10 7.5"
                  stroke="#0E1B2F"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
