import React from "react";
import PropTypes from "prop-types";
import "../Dashboard.css";
import externalLinkIcon from "../assets/external-link.svg";

const JobLinkButton = ({ jobLink, isEditing, onChange, errorMessage }) => {
  return (
    <div className="input-wrapper">
      {isEditing ? (
        <>
          <input
            type="text"
            value={jobLink}
            onChange={onChange}
            className={`input-field ${errorMessage ? "error" : ""}`}
            placeholder="Enter job link"
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </>
      ) : (
        jobLink && (
          <a
            href={jobLink}
            target="_blank"
            rel="noopener noreferrer"
            className="job-link-button"
          >
            <img
              src={externalLinkIcon}
              alt="External Link"
              className="link-icon"
            />
            <span>Link</span>
          </a>
        )
      )}
    </div>
  );
};

JobLinkButton.propTypes = {
  jobLink: PropTypes.string,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string, // Added to display the error message
};

export default JobLinkButton;
