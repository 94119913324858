import React from "react";
import "../Dashboard.css";
import checkIcon from "../assets/check.svg"; // Import the SVG

const SaveJobButton = ({ onClick }) => {
  return (
    <button className="save-job-button" onClick={onClick}>
      <img src={checkIcon} alt="Save" />
      {/*<span>Save</span>*/}
    </button>
  );
};

export default SaveJobButton;
