import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../context/AuthContext";
import { getNotifications, markNotificationAsRead } from "../api"; // Assuming these API functions are set up

const Notifications = () => {
  const { user } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getNotifications(user.USER_ID);
        setNotifications(response);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [user.USER_ID]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      await markNotificationAsRead(user.USER_ID, notificationId);
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.Notification_ID === notificationId
            ? { ...notif, read_status: true }
            : notif
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <NotificationContainer>
      <h2>Notifications</h2>
      <NotificationList>
        {notifications.map((notif) => (
          <NotificationItem key={notif.Notification_ID}>
            <p>{notif.Message}</p>
            {!notif.read_status && (
              <MarkAsReadButton
                onClick={() => handleMarkAsRead(notif.Notification_ID)}
              >
                Mark as Read
              </MarkAsReadButton>
            )}
          </NotificationItem>
        ))}
      </NotificationList>
    </NotificationContainer>
  );
};

// Styled components for Notifications
const NotificationContainer = styled.div`
  padding: 20px;
`;

const NotificationList = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const NotificationItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const MarkAsReadButton = styled.button`
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
`;

export default Notifications;
