// src/AdminDashboard.js

import React, { useState } from "react";
import "../AdminDashboard.css";
import TransactionsPanel from "../components/TransactionPanel";
import UsersTable from "../components/AdminUsersTable";
import UserCreditManager from "../components/AdminUserCreditManager";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("Users");

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "Users":
        return <UsersTable />;
      case "Transactions":
        return <TransactionsPanel />;
      case "Credit Manager":
        return <UserCreditManager />;
      default:
        return <UsersTable />;
    }
  };

  return (
    <div className="admin-dashboard">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "Users" ? "active" : ""}`}
          onClick={() => setActiveTab("Users")}
        >
          Users
        </button>
        <button
          className={`tab-button ${
            activeTab === "Transactions" ? "active" : ""
          }`}
          onClick={() => setActiveTab("Transactions")}
        >
          Transactions
        </button>
        <button
          className={`tab-button ${
            activeTab === "Credit Manager" ? "active" : ""
          }`}
          onClick={() => setActiveTab("Credit Manager")}
        >
          Credit Manager
        </button>
      </div>
      <div className="tab-content">{renderActiveTabContent()}</div>
    </div>
  );
};

export default AdminDashboard;
