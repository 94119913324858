// src/pages/VerifyEmail.js

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Dashboard.css"; // Reuse Dashboard styles
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState("Verifying..."); // Possible statuses: Verifying, Success, Error
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verify = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      const id = queryParams.get("id");

      if (!token || !id) {
        setStatus("Error");
        setMessage("Invalid verification link.");
        toast.error("Invalid verification link.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/verify-email`,
          {
            params: { token, id },
          }
        );

        if (response.status === 200) {
          setStatus("Success");
          setMessage("Your account has been successfully verified!");
          toast.success("Account verified successfully!");
          // Redirect to login directly
          navigate("/login");
        }
      } catch (error) {
        console.error("Verification error:", error);
        setStatus("Error");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setMessage(error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          setMessage("An unexpected error occurred.");
          toast.error("An unexpected error occurred.");
        }
      }
    };

    verify();
  }, [location.search, navigate]);

  return (
    <div
      className="dashboard-container"
      style={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="verification-message"
        style={{
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        {status === "Verifying" && (
          <>
            <h2>Verifying your account...</h2>
            <div
              className="loading-spinner"
              style={{ margin: "20px auto" }}
            ></div>
          </>
        )}
        {status === "Success" && (
          <>
            <h2>Account Activated Successfully!</h2>
            <p>Redirecting to login page...</p>
          </>
        )}
        {status === "Error" && (
          <>
            <h2>Verification Failed</h2>
            <p>{message}</p>
            <button
              onClick={() => navigate("/login")}
              className="action-button"
              style={{ marginTop: "20px" }}
            >
              Go to Login
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
