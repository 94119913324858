// src/pages/PaymentSuccess.js

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserProfile } from "../api"; // Ensure this API fetches current user details

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("session_id");

    const fetchUserData = async () => {
      try {
        const userData = await getUserProfile(); // Fetch the updated user data
        //console.log("Updated user data:", userData); // Verify the new credit balance
        toast.success(
          `Payment successful! Credits updated: ${userData.credit_balance}`
        );
      } catch (error) {
        console.error("Failed to fetch updated user data:", error);
      }
    };

    if (sessionId) {
      fetchUserData();
    } else {
      toast.error("Payment was not successful. Please try again.");
    }

    // Redirect to dashboard after 3 seconds
    const timer = setTimeout(() => {
      navigate("/dashboard");
    }, 3000);

    return () => clearTimeout(timer);
  }, [location, navigate]);

  return (
    <div className="dashboard-container">
      <h2>Payment Successful!</h2>
      <p>Your credits will be added to your account shortly.</p>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default PaymentSuccess;
