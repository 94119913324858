import React from "react";
import tailorIcon from "../assets/tailor-icon.svg"; // Ensure the path is correct to your tailor icon
import "../Dashboard.css"; // Import the CSS file where the styles will be placed
import { toast } from "react-toastify";

const TailorButton = ({
  creditBalance,
  onTailor,
  onClick,
  text = "Tailor",
}) => {
  return (
    <button
      className="tailor-button"
      disabled={creditBalance < 1}
      onClick={onClick}
    >
      <div className="tailor-icon-container">
        <img src={tailorIcon} alt="Tailor Icon" className="tailor-icon" />
      </div>
      <div className="tailor-text">{text}</div>
    </button>
  );
};

export default TailorButton;
