import React from "react";
import PropTypes from "prop-types";

const NotificationRingIcon = ({ unreadCount }) => {
  return (
    <div className="notification-icon-container">
      {/* Bell Icon */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M12.2218 22.0945C13.3218 22.0945 14.2218 21.1945 14.2218 20.0945H10.2218C10.2218 21.1945 11.1118 22.0945 12.2218 22.0945ZM18.2218 16.0945V11.0945C18.2218 8.02448 16.5818 5.45448 13.7218 4.77448V4.09448C13.7218 3.26448 13.0518 2.59448 12.2218 2.59448C11.3918 2.59448 10.7218 3.26448 10.7218 4.09448V4.77448C7.8518 5.45448 6.2218 8.01448 6.2218 11.0945V16.0945L4.2218 18.0945V19.0945H20.2218V18.0945L18.2218 16.0945Z"
          fill="#84818A"
        />
      </svg>

      {/* Notification Badge */}
      {unreadCount > 0 && (
        <div className="notification-badge">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
          >
            <g clipPath="url(#clip0_2050_185)">
              <rect
                x="0.221802"
                y="0.0944824"
                width="8"
                height="8"
                rx="4"
                fill="#00A488"
              />
            </g>
            <defs>
              <clipPath id="clip0_2050_185">
                <rect
                  width="8"
                  height="8"
                  fill="white"
                  transform="translate(0.221802 0.0944824)"
                />
              </clipPath>
            </defs>
          </svg>
          <span className="notification-count">{unreadCount}</span>
        </div>
      )}
    </div>
  );
};

NotificationRingIcon.propTypes = {
  unreadCount: PropTypes.number.isRequired,
};

export default NotificationRingIcon;
