import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { AuthContext } from "../context/AuthContext";
import { getUserProfile } from "../api";
import useWebSocket from "../hooks/useWebSocket";
import logo from "../assets/logo.png";
import NotificationRingIcon from "./NotificationRingIcon";
import CreditButton from "./CreditButton";
import ProfileDropDownButton from "./ProfileDropDownButton";
import ThemeToggle from "../ThemeToggle";
import "../styles.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const [userProfile, setUserProfile] = useState(null);
  const [creditBalance, setCreditBalance] = useState(0);
  const wsUrl = process.env.REACT_APP_WS_URL;

  const handleLogout = () => {
    logout();
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        setUserProfile(profile);
        setCreditBalance(profile.credit_balance);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        logout(); // Log out if the profile fetch fails
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    } else {
      setUserProfile(null);
    }
  }, [isAuthenticated, logout]);

  const handleWebSocketMessage = (newBalance) => {
    setCreditBalance(newBalance);
  };

  useWebSocket(
    isAuthenticated && userProfile ? wsUrl : null,
    handleWebSocketMessage
  );

  // Check if on home page and authenticated
  const isHome = location.pathname === "/";
  const isLoginOrSignup =
    location.pathname === "/login" || location.pathname === "/signup";

  return (
    <header className="navbar">
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
            <span className="beta-tag">BETA</span>
          </div>
        </a>
        <nav className={`navbar-menu ${menuOpen ? "open" : ""}`}>
          <a href="/">Home</a>
          <a href="https://blog.applimatch.co/">Blog</a>
          <a href="/#faq-section-id">FAQ</a>
          {isAuthenticated && <a href="/contact-us">Contact Us</a>}
        </nav>

        <div className="navbar-buttons">
          {isAuthenticated ? (
            isHome ? (
              <button
                onClick={handleDashboardClick}
                className="navbar-button dashboard-button"
              >
                Dashboard
              </button>
            ) : (
              <>
                <NotificationRingIcon unreadCount={3} />
                <CreditButton creditBalance={creditBalance} />
                <ProfileDropDownButton />
                <ThemeToggle />
              </>
            )
          ) : (
            <>
              <button
                onClick={handleLoginClick}
                className="navbar-button login-button"
              >
                Log In
              </button>
              <button
                onClick={handleSignupClick}
                className="navbar-button signup-button"
              >
                Join Now
              </button>
            </>
          )}
        </div>
        {/* Burger menu for mobile */}
        <button className="burger-menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span className="burger-bar"></span>
          <span className="burger-bar"></span>
          <span className="burger-bar"></span>
        </button>
      </div>
    </header>
  );
};

export default Header;
