import React, { useEffect, useState } from "react";
import { getAllTransactions } from "../api";
import { format } from "date-fns";

const TransactionsPanel = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("DATE");
  const [sortOrder, setSortOrder] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const LIMIT = 25;

  const fetchTransactions = async (startKey = null) => {
    setIsLoading(true);
    try {
      const data = await getAllTransactions(LIMIT, startKey);
      const newTransactions = data.transactions || [];

      // Filter out duplicates by checking TRANSACTION_ID
      const updatedTransactions = [
        ...transactions,
        ...newTransactions.filter(
          (newTx) =>
            !transactions.some(
              (existingTx) => existingTx.TRANSACTION_ID === newTx.TRANSACTION_ID
            )
        ),
      ];

      // Update the transactions with the unique list
      setTransactions(updatedTransactions);
      setLastEvaluatedKey(data.lastEvaluatedKey || null);
      setError(null); // Clear any errors

      // Apply filters after updating transactions
      applyFilters(updatedTransactions);
    } catch (err) {
      console.error("Error fetching transactions:", err);
      setError("Failed to load transactions.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Initial fetch for the first page
    fetchTransactions();
  }, []);

  // Filter and sort logic
  const applyFilters = (transactionsList) => {
    let updatedList = transactionsList;

    // Search filter
    if (searchTerm) {
      updatedList = updatedList.filter(
        (transaction) =>
          transaction.TRANSACTION_ID.includes(searchTerm) ||
          transaction.USER_ID.includes(searchTerm) ||
          transaction.ACTION_TYPE.includes(searchTerm)
      );
    }

    // Sort by selected key and order
    updatedList.sort((a, b) => {
      const aKey = a[sortKey];
      const bKey = b[sortKey];

      if (sortOrder === "asc") {
        return aKey > bKey ? 1 : -1;
      } else {
        return aKey < bKey ? 1 : -1;
      }
    });

    setFilteredTransactions(updatedList);
  };

  useEffect(() => {
    // Apply filters whenever the search term, sort key, or sort order changes
    applyFilters(transactions);
  }, [searchTerm, sortKey, sortOrder, transactions]);

  const handleLoadMore = () => {
    if (lastEvaluatedKey) {
      fetchTransactions(lastEvaluatedKey);
    }
  };

  return (
    <div className="panel">
      <h3>Transactions</h3>

      {/* Search and Filter Options */}
      <div className="transaction-controls">
        <input
          type="text"
          placeholder="Search transactions..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: "10px" }}
        />

        <label>Sort by: </label>
        <select onChange={(e) => setSortKey(e.target.value)} value={sortKey}>
          <option value="DATE">Date</option>
          <option value="AMOUNT">Amount</option>
          <option value="ACTION_TYPE">Action Type</option>
        </select>

        <label>Order: </label>
        <select
          onChange={(e) => setSortOrder(e.target.value)}
          value={sortOrder}
        >
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
      </div>

      <div
        className="content-section transactions-table-container"
        style={{ maxHeight: "600px", overflowY: "auto" }}
      >
        {filteredTransactions.length > 0 ? (
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>User ID</th>
                <th>Action Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredTransactions.map((tx) => (
                <tr key={tx.TRANSACTION_ID}>
                  <td>{tx.TRANSACTION_ID}</td>
                  <td>{tx.USER_ID}</td>
                  <td>{tx.ACTION_TYPE}</td>
                  <td>{tx.AMOUNT}</td>
                  <td>{format(new Date(tx.DATE), "PPpp")}</td>
                  <td>
                    {tx.DETAILS
                      ? Object.entries(tx.DETAILS).map(([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {JSON.stringify(value)}
                          </div>
                        ))
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No transactions found.</p>
        )}
        {error && <p className="error-message">{error}</p>}
      </div>

      {/* Load More Button */}
      {lastEvaluatedKey && (
        <div className="load-more-container">
          <button onClick={handleLoadMore} disabled={isLoading}>
            {isLoading ? "Loading..." : "Load More"}
          </button>
        </div>
      )}
    </div>
  );
};

export default TransactionsPanel;
