// src/pages/HomePage.js

import React from "react";
import InitialSignup from "../components/initialSignup";
import FAQAccordion from "../components/FAQAccordion";
import "../styles.css";
import logo from "../assets/logo.png";
import Dashboard from "../assets/Dashboard.png";
import { useEffect, useRef, useState } from "react";
import homepage from "../assets/homepage_updated.png";
import progressTracker from "../assets/progress_tracker.png";
import statsPanels from "../assets/stats_panels.png";
import jobsTable from "../assets/jobs_table.png";
import bulkTailorButtons from "../assets/bulk_tailor_buttons.png";
import EmailBanner from "../assets/EmailBanner.png";
import TawkTo from "../components/TawkTo";
import { Analytics } from "@vercel/analytics/react";
import Footer from "../components/Footer";

const Home = () => {
  const words = ["resumes", "letters", "applications"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu
  const wordRef = useRef(null);

  useEffect(() => {
    // Update the underline width based on the new word's size
    if (wordRef.current) {
      setUnderlineWidth(wordRef.current.offsetWidth);
    }
  }, [currentWordIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);

      // Change text after animation, and then update the underline width
      setTimeout(() => {
        setIsAnimating(false);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 500); // Duration of the blur-out animation
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const signupRef = useRef(null);

  const handleSignupClick = () => {
    signupRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Animation effect for floating the images
    const figures = document.querySelectorAll(".background-figure");
    figures.forEach((figure) => {
      let direction = 1; // 1 for up, -1 for down
      let position = 0;

      setInterval(() => {
        if (position >= 15 || position <= -15) {
          direction *= -1;
        }
        position += direction * 0.1;
        figure.style.transform = `translateY(${position}px)`;
      }, 50);
    });
  }, []);

  return (
    <div className="homepage-container">
      <Analytics />
      <TawkTo />
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Land your dream job faster</h1>
          <div className="powered-by">
            <span> with personalized </span>
            <span className="highlight">
              <span
                ref={wordRef}
                className={`animated-word ${
                  isAnimating ? "fade-out" : "fade-in"
                }`}
              >
                {" "}
                {words[currentWordIndex]}
              </span>
              {/* SVG for the underline effect */}
              <svg
                className="underline-svg"
                aria-hidden="true"
                viewBox="0 0 418 42"
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: `${underlineWidth}px` }}
                preserveAspectRatio="none"
              >
                <path
                  d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"
                  fill="#4981fd"
                />
              </svg>
            </span>
          </div>
          <p>
            Stand out with personalized resumes and cover letters, track your
            application status in real-time, and increase your chances of
            landing interviews, all in one platform.
          </p>
          <a className="cta-button" href="/signup">
            Sign Up – It's Free!
          </a>
        </div>
        <img
          src={progressTracker}
          className="floating-figure progress-tracker"
          alt="Progress Tracker"
        />
        <img
          src={statsPanels}
          className="floating-figure stats-panels"
          alt="Stats Panels"
        />
        <img
          src={jobsTable}
          className="home-jobs-table"
          alt="Jobs Table"
          onClick={handleSignupClick}
        />
      </section>

      {/* Features Section */}
      {/* 
      <section className="features-section">
        <div className="feature-item">
          <div className="feature-image">
            <div class="mac-window">
              <span class="window-dot window-dot--red"></span>
              <span class="window-dot window-dot--yellow"></span>
              <span class="window-dot window-dot--green"></span>
            </div>
            <img src={Dashboard} alt="Feature 1" className="cropped-image-1" />
          </div>
          <div className="feature-text">
            <h2>
              Craft the perfect tailored resume for all jobs, with one click
            </h2>
            <p>
              Generate bulk job-specific resumes and cover letters effortlessly.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <div className="feature-image">
            <img src="/path/to/feature2.png" alt="Feature 2" />
          </div>
          <div className="feature-text">
            <h2>Market Value</h2>
            <p>
              Find how much the market is paying for your experience and skills.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <div className="feature-image">
            <img src="/path/to/feature3.png" alt="Feature 3" />
          </div>
          <div className="feature-text">
            <h2>Job Match Insights</h2>
            <p>
              Receive insights and job matching scores based on the best fit.
            </p>
          </div>
        </div>
        <div className="feature-item">
          <div className="feature-image">
            <img src="/path/to/feature4.png" alt="Feature 4" />
          </div>
          <div className="feature-text">
            <h2>Referral Connections</h2>
            <p>Instantly connect with referrals and streamline the job hunt.</p>
          </div>
        </div>
      </section>*/}

      {/* How AppliMatch Works Section *
      <section className="magic-section">
        <h2>How AppliMatch Works</h2>
        <div className="magic-steps-container">
          <div className="magic-step">
            <div className="magic-icon">
              <span role="img" aria-label="Profile">
                👤
              </span>
            </div>
            <h3>1. Enter Your Profile</h3>
            <p>Provide your skills, work experience, and target roles.</p>
          </div>
          <div className="magic-step">
            <div className="magic-icon">
              <span role="img" aria-label="Jobs">
                💼
              </span>
            </div>
            <h3>2. Add Job Listings</h3>
            <p>Select the jobs you're interested in.</p>
          </div>
          <div className="magic-step">
            <div className="magic-icon">
              <span role="img" aria-label="Resume">
                📄
              </span>
            </div>
            <h3>3. Get Tailored Applications</h3>
            <p>
              Receive high-quality resumes and cover letters for each job, all
              at once.
            </p>
          </div>
          <div className="magic-step">
            <div className="magic-icon">
              <span role="img" aria-label="Connections">
                🤝
              </span>
            </div>
            <h3>4. Connect with Referrals</h3>
            <p>
              Access a list of referrals and connections to boost your chances.
            </p>
          </div>
        </div>
      </section>*/}
      {/* Testimonials Section 
      <section className="testimonials-section">
        <h2>Success Stories with AppliMatch</h2>
        <div className="testimonials-container">
          <div className="testimonial">
            <div className="testimonial-icon">
              <img
                src="https://www.vectorlogo.zone/logos/mongodb/mongodb-ar21.svg"
                alt="MongoDB"
                className="company-logo"
              />
            </div>
            <p>"Secured a Software Engineering Internship at MongoDB."</p>
          </div>
          <div className="testimonial">
            <div className="testimonial-icon">
              <img
                src="https://cdn.worldvectorlogo.com/logos/jpmorgan.svg"
                alt="JPMorgan Chase"
                className="company-logo"
              />
            </div>
            <p>"Landed a Product Manager role at JPMorgan Chase."</p>
          </div>
          <div className="testimonial">
            <div className="testimonial-icon">
              <img
                src="https://cdn.worldvectorlogo.com/logos/snapchat-1.svg"
                alt="Snapchat"
                className="company-logo"
              />
            </div>
            <p>"Landed a Site Reliability Engineer role at Snap."</p>
          </div>
        </div>
      </section>*/}

      {/* Watch Tutorial Section */}
      {/* <section className="tutorial-section">
        <h2>
          🚀🚀🚀 More than 3,000 job applications are completed efficiently
          every day with AppliMatch!
        </h2>
        Video <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/dQw4w9WgXcQ"
            title="AppliMatch Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>*/}

      {/* Marquee Section */}
      {/*
      <section className="marquee-section">
        <h2>
          Supports all popular job boards, with 10,000,000+ jobs available
        </h2>
        <div className="marquee-container">
          <div className="marquee">
            <img
              src="https://vectorwiki.com/images/NNbMO__linkedin-logo-2013.svg"
              alt="LinkedIn"
            />
            <img
              src="https://vectorwiki.com/images/jFZHN__indeed-logo.svg"
              alt="Indeed"
            />
            <img
              src="https://vectorwiki.com/images/z85bU__workday-logo.svg"
              alt="WorkDay"
            />
            <img
              src="https://cdn.brandfetch.io/id7baa8wpg/w/800/h/179/idhk9sp03E.png?k=id64Mup7ac&t=1662654650793?t=1662654650793"
              alt="Greenhouse"
            />
            <img
              src="https://cdn.brandfetch.io/idHP9w7qSm/w/405/h/67/theme/dark/logo.png?k=id64Mup7ac&t=1724406448554?t=1724406448554"
              alt="Workable"
            />
            <img
              src="https://cdn.brandfetch.io/idomC5_5qC/theme/dark/logo.svg?k=id64Mup7ac&t=1721803768335?t=1721803768335"
              alt="Ashby"
            />
            <img
              src="https://vectorwiki.com/images/btnCE__glassdoor.svg"
              alt="Glassdoor"
            />
            <img
              src="https://cdn.brandfetch.io/id6lPO7l84/theme/dark/logo.svg?k=id64Mup7ac&t=1667702093463?t=1667702093463"
              alt="Lever"
            />
            <img
              src="https://vectorwiki.com/images/NNbMO__linkedin-logo-2013.svg"
              alt="LinkedIn"
            />
            <img
              src="https://vectorwiki.com/images/jFZHN__indeed-logo.svg"
              alt="Indeed"
            />
            <img
              src="https://vectorwiki.com/images/z85bU__workday-logo.svg"
              alt="WorkDay"
            />
            <img
              src="https://cdn.brandfetch.io/id7baa8wpg/w/800/h/179/idhk9sp03E.png?k=id64Mup7ac&t=1662654650793?t=1662654650793"
              alt="Greenhouse"
            />
            {/* ... 
          </div>
        </div>
      </section>*/}

      {/* Extra tools Section */}
      {/*
      <section className="extra-tools-section">
        <div className="extra-tools-header">
          <h1>More tools to help you get the interview.</h1>
          <p>Explore all the features we offer to optimize your job search.</p>
        </div>

        <div className="extra-tools-grid">
          <div className="tool-card">
            <img src="/path/to/image1.png" alt="Resume ATS Score" />
            <h3>Resume ATS Score</h3>
            <p>See what's wrong with your resume and how to fix it.</p>
          </div>

          <div className="tool-card">
            <img
              src="/path/to/image2.png"
              alt="Cover Letter & Email Generator"
            />
            <h3>Cover Letter & Email Generator</h3>
            <p>Craft personalized cover letters and networking emails.</p>
          </div>

          <div className="tool-card">
            <img src="/path/to/image3.png" alt="Career Journal" />
            <h3>Career Journal</h3>
            <p>Track all your career achievements and get promoted faster.</p>
          </div>

          <div className="tool-card">
            <img src="/path/to/image4.png" alt="Networking Copilot" />
            <h3>Networking Copilot</h3>
            <p>
              We help you find the hiring manager and send a personalized email.
            </p>
          </div>

          <div className="tool-card">
            <img src="/path/to/image5.png" alt="Job Lists" />
            <h3>Job Lists</h3>
            <p>
              Handpicked collections of the most exciting companies and
              opportunities.
            </p>
          </div>
        </div>
      </section>*/}

      {/* Testimonials Section */}

      <section className="testimonials-section">
        <div className="testimonials-header">
          <h1>Loved by people</h1>
          <p>
            Here's what some of our users have to say about how we've helped
            them.
          </p>
        </div>

        <div className="testimonials-grid">
          <figure className="testimonial-box">
            <svg
              aria-hidden="true"
              className="quote-icon"
              viewBox="0 0 105 78" /* Set the viewBox to match the original dimensions */
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet" /* Preserve the aspect ratio */
              style={{
                width: "40px",
                height: "auto",
              }} /* Example of controlling size */
            >
              <path
                d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                fill="#4981fd"
              />
            </svg>

            <blockquote className="testimonial-quote">
              <p>
                "AppliMatch helped me land 5 interviews in just two weeks!"
                <br /> <br /> <br />
              </p>
            </blockquote>
            <figcaption className="testimonial-author">
              {/*<div className="author-name">Kevin</div>*/}
              <div className="author-position">UX Designer</div>
            </figcaption>
          </figure>
          <figure className="testimonial-box">
            <svg
              aria-hidden="true"
              className="quote-icon"
              viewBox="0 0 105 78"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
              style={{
                width: "40px",
                height: "auto",
              }}
            >
              <path
                d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                fill="#4981fd"
              />
            </svg>
            <blockquote className="testimonial-quote">
              <p>
                "I've saved hours of time that I would've spent tailoring my
                resume for each internship."
                <br />
                <br />
              </p>
            </blockquote>
            <figcaption className="testimonial-author">
              {/*<div className="author-name">Clara</div>*/}
              <div className="author-position">Finance Major</div>
            </figcaption>
          </figure>
          <figure className="testimonial-box">
            <svg
              aria-hidden="true"
              className="quote-icon"
              viewBox="0 0 105 78"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
              style={{
                width: "40px",
                height: "auto",
              }}
            >
              <path
                d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                fill="#4981fd"
              />
            </svg>
            <blockquote className="testimonial-quote">
              <p>
                "I got 5x the number of interviews I was getting before."
                <br /> <br />
              </p>
            </blockquote>
            <figcaption className="testimonial-author">
              {/*<div className="author-name">Mike</div>*/}
              <div className="author-position">Computer Science Major</div>
            </figcaption>
          </figure>
        </div>
      </section>
      {/* Signup Section */}
      {/*<section id="signup" ref={signupRef} className="signup-section">
        <h2>Sign up now and get early access to premium features!</h2>
        <p>Sign up now and be the first to access AppliMatch!</p>
        <InitialSignup />
      </section>*/}
      {/* FAQ Section */}
      <FAQAccordion />

      {/* Footer */}
      {/*<footer className="footer">
        <p>
          &copy; 2024 AppliMatch. All rights reserved.
          <br />
          Contact us at{" "}
          <a href="mailto:contact@applimatch.co">contact@applimatch.co</a>
        </p>
      </footer>*/}
      <Footer />
      <section className="hidden">
        <img src={homepage} alt="Homepage" />
      </section>
    </div>
  );
};

export default Home;
