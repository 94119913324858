// src/components/PackagePricingBox.js

import React from "react";

const PackagePricingBox = ({ credits, price, isSelected, onSelect }) => {
  return (
    <div
      className={`panel ${isSelected ? "selected" : ""}`}
      onClick={() => onSelect(credits, price)}
      style={{
        cursor: "pointer",
        border: isSelected ? "2px solid #7D3FFF" : "2px solid transparent",
      }}
    >
      <div className="metric-title">{credits} Credits</div>
      <div className="metric-value">{price}</div>
    </div>
  );
};

export default PackagePricingBox;
