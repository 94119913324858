import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../api";
import ThemeToggle from "../ThemeToggle";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext

const ProfileDropdownButton = ({ userName, userId, avatarImage }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [firstName, setFirstName] = useState(""); // State to hold the user's first name
  const [lastName, setLastName] = useState("");
  const handleToggleDropdown = () => {
    setMenuOpen((prev) => !prev);
  };
  const { logout } = useContext(AuthContext);

  const handleCreditButtonClick = () => {};
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        // If no token is found, redirect to login
        navigate("/login");
      } else {
        try {
          // Try to fetch the user profile to validate the token
          const userProfile = await getUserProfile();
          setFirstName(userProfile.first_name); // Set the first name if authenticated
          setLastName(userProfile.last_name);
        } catch (error) {
          // If token is invalid or expired, redirect to login
          navigate("/login");
        }
      }
    };

    checkAuthentication(); // Call the function to check authentication
  }, [navigate]); // Ensure useNavigate is listed as a dependency

  const handleLogout = () => {
    logout();
    // navigate("/login"); // Not needed since logout redirects
  };

  return (
    <div className="profile-dropdown-container">
      {/* Credit Button */}
      <div className="credit-button" onClick={handleCreditButtonClick}></div>
      {/* Button Section */}
      <div className="profile-button" onClick={handleToggleDropdown}>
        {/* Avatar */}
        <div
          className="avatar"
          style={{
            background: avatarImage
              ? `url(${avatarImage}) lightgray 50% / cover no-repeat`
              : "#4981fd",
          }}
        >
          {!avatarImage && userName && userName[0]}{" "}
          {firstName[0] + " " + lastName[0]}
        </div>

        {/* User Info */}
        <div className="user-info">
          <div className="user-name">
            {firstName} {lastName}
          </div>
          <div className="user-id">User</div>
        </div>

        {/* Arrow Icon */}
        <div className="dropdown-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M5.37109 8.09448L10.3711 13.0945L15.3711 8.09448H5.37109Z"
              fill="#84818A"
            />
          </svg>
        </div>
      </div>

      {/* Dropdown Menu */}
      {menuOpen && (
        <div className="dropdown-menu">
          <ul>
            {/*<li>Profile</li>
            <li>Settings</li>*/}
            <li onClick={handleLogout}>Log Out</li>
          </ul>
        </div>
      )}
    </div>
  );
};

// Define prop types for better readability and maintainability
ProfileDropdownButton.propTypes = {
  userName: PropTypes.string,
  userId: PropTypes.string,
  avatarImage: PropTypes.string,
};

export default ProfileDropdownButton;
