import React from "react";

const RefundPolicy = () => {
  return (
    <div className="refund-policy-container">
      <h1>Refund Policy</h1>

      <section>
        <p>
          At Applimatch, we aim to provide value and transparency in helping you
          streamline your job applications. To ensure users have the opportunity
          to experience our services before committing to a purchase, each new
          user receives <strong>20 free credits</strong> upon signing up. Each
          resume or cover letter generated through Applimatch requires 1 credit,
          allowing you to fully explore our service and decide if it meets your
          needs.
        </p>
      </section>

      <section>
        <h2>No Refunds on Purchased Credits</h2>
        <p>
          Please note that once you purchase credits, all sales are final, and
          we do not offer refunds under normal circumstances. This policy
          applies to all credit purchases, regardless of the number of credits
          purchased. We encourage you to use the initial free credits to
          determine the suitability of our services before purchasing additional
          credits.
        </p>
      </section>

      <section>
        <h2>Credit Purchase Plans</h2>
        <ul>
          <li>
            <strong>10 credits</strong> for <strong>$5</strong>
          </li>
          <li>
            <strong>25 credits</strong> for <strong>$10</strong>
          </li>
          <li>
            <strong>50 credits</strong> for <strong>$18</strong>
          </li>
          <li>
            <strong>100 credits</strong> for <strong>$30</strong>
          </li>
        </ul>
      </section>

      <section>
        <h2>Exceptions to the No-Refund Policy</h2>
        <p>In certain situations, refunds may be considered if:</p>
        <ul>
          <li>
            You experience a <strong>technical issue</strong> that prevents you
            from using your credits.
          </li>
          <li>
            The <strong>service was not delivered as promised</strong>, and we
            are unable to rectify the issue.
          </li>
        </ul>
        <p>
          If you believe you are eligible for a refund under these
          circumstances, please contact us at{" "}
          <a href="mailto:contact@applimatch.co">contact@applimatch.co</a>{" "}
          within <strong>3 days of your purchase</strong> and provide details of
          the issue. We will review your request and respond with a decision
          within <strong>5-7 business days</strong>.
        </p>
      </section>

      <section>
        <h2>Chargebacks</h2>
        <p>
          If you initiate a chargeback with your bank or payment provider,
          Applimatch reserves the right to suspend your account and restrict
          access to our services until the issue is resolved. To avoid any
          interruptions to your service, we encourage you to reach out to us
          directly to resolve billing concerns before initiating a chargeback.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          For any further questions or concerns, please contact us at{" "}
          <a href="mailto:contact@applimatch.co">contact@applimatch.co</a>.
        </p>
      </section>

      <footer>
        <p>
          &copy; {new Date().getFullYear()} Applimatch. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default RefundPolicy;
