import React from "react";
import downloadIcon from "../assets/download.svg"; // Ensure the path is correct to your download icon
import "../Dashboard.css"; // Import the CSS file where the styles will be placed

const DownloadButton = ({ link, text = "Download" }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="download-button"
    >
      <div className="download-icon-container">
        <img src={downloadIcon} alt="Download Icon" className="download-icon" />
      </div>
      <div className="download-text">{text}</div>
    </a>
  );
};

export default DownloadButton;
