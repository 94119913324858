import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>

      <section>
        <p>
          THESE TERMS OF SERVICE GOVERN YOUR ACCESS TO AND USE OF THE SERVICES
          PROVIDED BY AppliMatch ("Company"). BY (A) PURCHASING ACCESS TO THE
          SERVICE THROUGH AN ONLINE ORDERING PROCESS THAT REFERENCES THIS
          AGREEMENT, (B) SIGNING UP FOR A FREE OR PAID ACCESS PLAN FOR THE
          SERVICE VIA A PLATFORM THAT REFERENCES THIS AGREEMENT, OR (C) CLICKING
          A BOX INDICATING ACCEPTANCE, YOU AGREE TO BE BOUND BY THE TERMS OF
          THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE
          SERVICES.
        </p>
      </section>

      <section>
        <h2>1. The Service</h2>
        <h3>1.1 Service Description</h3>
        <p>
          AppliMatch offers a cloud-based service designed to help students
          optimize their job application processes. AppliMatch enables users to
          efficiently apply to multiple job postings, providing tools for
          generating tailored resumes and cover letters for each application.
          Users are solely responsible for the content they upload or generate
          using the Service.
        </p>

        <h3>1.2 Subscription</h3>
        <p>
          Access to the Service is provided through a credit-based subscription
          plan selected during the ordering process. Users purchase credits,
          which are used to apply to jobs. Each job application requires 2
          credits: 1 credit for generating a tailored resume and 1 credit for
          generating a customized cover letter. The number of credits required
          and available will be outlined in the Order. Credits are valid for a
          specific period, as outlined in the Order, and any unused credits may
          expire if not used within that period. Users may use the Service
          solely for their personal job application purposes and in accordance
          with this Agreement.
        </p>

        <h3>1.3 Ownership</h3>
        <p>
          AppliMatch retains all rights, title, and interest in the Service,
          including any software, templates, scripts, and documentation provided
          ("AppliMatch Materials"). No rights are granted to you except those
          expressly outlined in this Agreement.
        </p>

        <h3>1.4 Permissions</h3>
        <p>
          You are responsible for managing user access and permissions within
          your account. AppliMatch is not liable for any actions resulting from
          the permissions you set.
        </p>
      </section>

      <section>
        <h2>2. Restrictions</h2>
        <h3>2.1 Use Restrictions</h3>
        <p>
          You may not: (a) modify, create derivative works of, or
          reverse-engineer the Service; (b) sublicense or resell access to the
          Service; (c) use the Service for illegal purposes or to infringe on
          third-party rights; (d) attempt to disrupt the Service or access data
          without authorization.
        </p>
      </section>

      <section>
        <h2>3. Third-Party Services</h2>
        <p>
          The Service may integrate with third-party services ("Third-Party
          Services"). Your use of Third-Party Services is governed by their
          respective terms and is at your own risk. AppliMatch is not liable for
          issues arising from such integrations.
        </p>
      </section>

      <section>
        <h2>4. Payment and Fees</h2>
        <h3>4.1 Fees</h3>
        <p>
          You agree to pay the subscription fees as specified in the Order. Fees
          are non-refundable except as explicitly stated in this Agreement.
        </p>

        <h3>4.2 Payment</h3>
        <p>
          Payments will be processed via the payment method provided. You are
          responsible for maintaining accurate billing information. Failure to
          provide accurate information may result in service suspension.
        </p>

        <h3>4.3 Taxes</h3>
        <p>
          You are responsible for any taxes associated with your purchase of the
          Service.
        </p>

        <h3>4.4 Failure to Pay</h3>
        <p>
          If you fail to pay any fees when due, AppliMatch may suspend access to
          the Service until overdue amounts are paid.
        </p>
      </section>

      <section>
        <h2>5. Term and Termination</h2>
        <h3>5.1 Agreement Term and Renewals</h3>
        <p>
          This Agreement begins on the start date of the Subscription and
          remains effective until terminated as outlined herein. Subscriptions
          automatically renew unless canceled before the renewal date.
        </p>

        <h3>5.2 Termination</h3>
        <p>
          Either party may terminate this Agreement for material breach with
          written notice, provided the breach is not cured within 30 days.
        </p>

        <h3>5.3 Effect of Termination</h3>
        <p>
          Upon termination, your access to the Service will be revoked. Any
          remaining fees are payable.
        </p>
      </section>

      <section>
        <h2>6. Warranties and Disclaimers</h2>
        <h3>6.1 Warranties</h3>
        <p>
          You represent that your use of the Service complies with all
          applicable laws.
        </p>

        <h3>6.2 Disclaimer</h3>
        <p>
          The Service is provided "as is" without warranties of any kind.
          AppliMatch disclaims all implied warranties, including those of
          merchantability and fitness for a particular purpose.
        </p>
      </section>

      <section>
        <h2>7. Limitation of Liability</h2>
        <p>
          AppliMatch’s total liability for any claim will not exceed the amount
          paid for the Service in the twelve months preceding the claim.
          AppliMatch will not be liable for any indirect or consequential
          damages.
        </p>
      </section>

      <section>
        <h2>8. Confidentiality</h2>
        <h3>8.1 Definition</h3>
        <p>
          Confidential Information includes non-public information shared by
          either party. This Agreement, User Submissions, and
          AppliMatch-provided materials are considered Confidential Information.
        </p>

        <h3>8.2 Use and Protection</h3>
        <p>
          The receiving party agrees to protect the disclosing party’s
          Confidential Information with reasonable care and use it only for
          fulfilling obligations under this Agreement.
        </p>
      </section>

      <section>
        <h2>9. Data</h2>
        <h3>9.1 User Information</h3>
        <p>
          You authorize AppliMatch to store and process User Information as part
          of the Service. You retain rights to your User Submissions, and
          AppliMatch will not access or use them except as necessary to provide
          the Service.
        </p>

        <h3>9.2 Data Protection</h3>
        <p>
          AppliMatch employs reasonable measures to secure data, but you are
          responsible for securing your own data and systems.
        </p>
      </section>

      <section>
        <h2>10. General Terms</h2>
        <h3>10.1 Governing Law</h3>
        <p>
          This Agreement is governed by the laws of the United States of
          America. Any disputes will be resolved in the courts of the United
          States of America.
        </p>

        <h3>10.2 Entire Agreement</h3>
        <p>
          This Agreement constitutes the entire understanding between the
          parties and supersedes all prior agreements. Any amendments must be in
          writing and signed by both parties.
        </p>

        <h3>10.3 Assignment</h3>
        <p>
          You may not assign this Agreement without AppliMatch's prior written
          consent.
        </p>
      </section>

      <footer>
        <p>
          &copy; {new Date().getFullYear()} AppliMatch. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default TermsOfService;
