import React, { useState } from "react";
import "../Dashboard.css";
import { submitContactForm } from "../api";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [screenshot, setScreenshot] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const [fileError, setFileError] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        setFileError("Only JPG, JPEG, and PNG files are allowed.");
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setFileError("File size must not exceed 10 MB.");
        return;
      }
      setFileError(""); // Clear any previous error
      setScreenshot(file); // Set the file only if it's valid
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.message.length < 30) {
      alert("Message must be at least 30 characters long");
      return;
    }

    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("email", formData.email);
    formDataObj.append("subject", formData.subject);
    formDataObj.append("message", formData.message);
    if (screenshot) formDataObj.append("screenshot", screenshot);

    try {
      const response = await submitContactForm(formDataObj);
      //console.log("Form submitted successfully:", response);
      setShowThankYou(true);
      setFormData({ name: "", email: "", subject: "", message: "" });
      setScreenshot(null);
      setTimeout(() => setShowThankYou(false), 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(error.message || "Failed to submit contact form"); // Graceful alert for the user
    }
  };

  return (
    <div className="dashboard-container">
      <div
        className="panel"
        style={{ maxWidth: "600px", margin: "0 auto", paddingRight: "40px" }}
      >
        <center>
          <h2>Contact Us</h2>
        </center>
        {showThankYou && (
          <div className="thank-you-message">
            Thank you for your message! We'll get back to you soon.
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <input
              type="text"
              name="name"
              className="input-field"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-wrapper">
            <input
              type="email"
              name="email"
              className="input-field"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-wrapper">
            <input
              type="text"
              name="subject"
              className="input-field"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-wrapper">
            <input
              type="file"
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFileChange}
              className="input-field"
            />
            <small>
              Optional: Upload a screenshot (JPG, JPEG, PNG, max 10 MB)
            </small>
          </div>

          <div className="input-wrapper">
            <textarea
              name="message"
              className="input-field"
              placeholder="Your Message (minimum 30 characters)"
              value={formData.message}
              onChange={handleChange}
              required
              minLength={30}
              style={{ minHeight: "150px", resize: "vertical" }}
            />
            <small className="character-count">
              {formData.message.length}/30 characters minimum
            </small>
          </div>

          <button
            type="submit"
            className="action-button"
            style={{
              background: "linear-gradient(247deg, #16c2fc 0%, #7d3fff 100%)",
              color: "white",
              width: "100%",
              marginTop: "20px",
            }}
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};
export default ContactUs;
