import React from "react";
import PropTypes from "prop-types";
import { CreditContext } from "../context/CreditContext";
import { useContext } from "react";
const CreditButton = () => {
  const { creditBalance } = useContext(CreditContext);

  return (
    <div className="credit-button-container">
      {/* Icon Column */}
      <div className="icon-column">
        <div className="icon-circle">
          <div className="star-frame">
            <div className="star-icon">
              <svg
                width="27"
                height="28"
                viewBox="0 0 27 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Frame" clip-path="url(#clip0_2050_175)">
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M23.2836 8.98037L16.4191 8.34371L13.6923 2.01184C13.5013 1.56848 12.8727 1.56848 12.6818 2.01184L9.95503 8.34366L3.09049 8.98037C2.60982 9.02492 2.41559 9.62278 2.77822 9.94137L7.95754 14.4913L6.4418 21.2167C6.33565 21.6876 6.84425 22.057 7.25928 21.8106L13.1871 18.2908L19.1148 21.8106C19.5299 22.0571 20.0384 21.6876 19.9323 21.2167L18.4165 14.4913L23.5959 9.94137C23.9585 9.62278 23.7642 9.02492 23.2836 8.98037Z"
                      fill="#FFDC64"
                    />
                    <path
                      id="Vector_2"
                      d="M6.16941 1.79382C6.51936 2.27989 7.5071 4.36736 8.0718 5.58392C8.1606 5.77516 7.92064 5.94793 7.76743 5.80303C6.79288 4.88161 5.12655 3.28267 4.7766 2.79655C4.49969 2.41195 4.587 1.87569 4.97164 1.59878C5.35624 1.32187 5.8925 1.40923 6.16941 1.79382ZM20.2045 1.79382C19.8546 2.27989 18.8668 4.36736 18.3021 5.58392C18.2133 5.77516 18.4533 5.94793 18.6065 5.80303C19.581 4.88161 21.2473 3.28261 21.5973 2.79655C21.8742 2.41195 21.7869 1.87569 21.4023 1.59878C21.0177 1.32187 20.4814 1.40923 20.2045 1.79382ZM25.7344 16.0477C25.165 15.862 22.8761 15.5552 21.5455 15.3868C21.3364 15.3603 21.2447 15.6415 21.4292 15.7434C22.6032 16.3919 24.6329 17.4936 25.2023 17.6793C25.6528 17.8263 26.1372 17.5801 26.2841 17.1295C26.4311 16.6789 26.185 16.1946 25.7344 16.0477ZM0.639481 16.0477C1.2089 15.862 3.49784 15.5552 4.82839 15.3868C5.03755 15.3603 5.12922 15.6415 4.9447 15.7434C3.77069 16.3919 1.74106 17.4936 1.17164 17.6793C0.721092 17.8263 0.236718 17.5801 0.0897681 17.1295C-0.0571814 16.6789 0.188932 16.1946 0.639481 16.0477ZM12.3552 25.7321C12.3552 25.1332 12.7732 22.862 13.0257 21.5448C13.0654 21.3377 13.3611 21.3377 13.4008 21.5448C13.6533 22.862 14.0714 25.1332 14.0714 25.7321C14.0714 26.206 13.6872 26.5902 13.2133 26.5902C12.7393 26.5903 12.3552 26.206 12.3552 25.7321Z"
                      fill="#FFF082"
                    />
                    <path
                      id="Vector_3"
                      d="M14.6838 4.31438L13.6922 2.01184C13.5013 1.56848 12.8727 1.56848 12.6818 2.01184L9.95498 8.34366L3.09049 8.98037C2.60982 9.02492 2.41559 9.62278 2.77822 9.94137L7.95754 14.4913L6.4418 21.2167C6.33565 21.6876 6.84425 22.057 7.25928 21.8106L8.04823 21.3421C9.34162 12.9697 12.9777 6.82366 14.6838 4.31438Z"
                      fill="#FFC850"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2050_175">
                    <rect
                      width="26.2795"
                      height="26.2795"
                      fill="white"
                      transform="translate(0.0472412 0.873901)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Credit Details Column */}
      <div className="credit-details">
        <div className="credit-label">Credit Balance</div>
        <div className="credit-value">{creditBalance}</div>
      </div>
    </div>
  );
};

CreditButton.propTypes = {};

export default CreditButton;
