// MetricPanel.js
import React from "react";
import ArrowUp from "./ArrowUp";
const MetricPanel = ({ icon, title, value, change }) => {
  return (
    <div className="metric-panel">
      <div className="metric-header">
        <div className="icon-container">
          <img src={icon} alt={title} />
        </div>
        <div className="metric-title">{title}</div>
      </div>
      <div className="metric-value">
        {value.toLocaleString()} {/* Formatting value with commas */}
      </div>
      <div className="metric-change">
        <ArrowUp color="#12B76A" />
        <span className="percentage">{change}%</span>
        <span className="comparison-text">vs last month</span>
      </div>
    </div>
  );
};

export default MetricPanel;
