import React from "react";
import "../styles.css"; // Assuming a dedicated stylesheet for this page

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1 className="title">Privacy Policy for Applimatch</h1>
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to Applimatch, an innovative job application assistant
            designed to streamline your job search by creating customized
            resumes and cover letters tailored for each application. At
            Applimatch, we respect and protect your privacy. This Privacy Policy
            outlines how we collect, use, share, and protect your personal
            information, and your rights concerning this information.
          </p>
          <p>
            For questions or concerns regarding this Privacy Policy, or to
            exercise your privacy rights, please reach out to us at:
          </p>
          <address>
            <strong>Applimatch</strong>
            <br />
            {/*1483 Balboa Ave
            <br />*/}
            Burlingame, CA 94010
            <br />
            Email:{" "}
            <a href="mailto:contact@applimatch.com">contact@applimatch.com</a>
          </address>
        </section>

        <section>
          <h2>1. How We Collect and Use Your Information</h2>
          <p>
            To provide our services, Applimatch may collect various types of
            information, including:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Your name, email address,
              job history, and educational background for generating customized
              resumes and cover letters.
            </li>
            <li>
              <strong>Job Preferences:</strong> Data about your desired job
              roles and career goals for tailored recommendations.
            </li>
            <li>
              <strong>Payment Information:</strong> Collected and processed
              securely via Stripe (see <em>Payment Processing</em>).
            </li>
            <li>
              <strong>Usage Data:</strong> Data on app usage and feature
              engagement to improve functionality.
            </li>
            <li>
              <strong>API Calls to 3rd Party Services:</strong> Utilized to
              process requests for AI-powered services.
            </li>
          </ul>
          <p>
            Aggregated and non-identifiable information may be used internally
            for research and analysis to improve our services.
          </p>
        </section>

        <section>
          <h2>2. Cookies and Tracking Technologies</h2>
          <p>
            Applimatch uses cookies and similar tracking technologies to enhance
            functionality and understand user preferences. Manage your cookie
            preferences in your device settings, but note that disabling cookies
            may affect certain features.
          </p>
        </section>

        <section>
          <h2>3. Sharing Information with Third Parties</h2>
          <p>
            Applimatch values your privacy and does not sell your personal
            information. Information is shared only with trusted third-party
            providers who help us deliver services, such as data hosting,
            analytics, and payment processing.
          </p>
        </section>

        <section>
          <h2>4. Payment Processing</h2>
          <p>
            Payments are securely handled by Stripe. Applimatch does not store
            credit card information. See Stripe’s{" "}
            <a
              href="https://stripe.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </section>

        <section>
          <h2>5. Data Storage and Retention</h2>
          <p>
            Your information is stored on secure servers and retained as
            necessary to provide services or as required by law. Deactivated
            accounts or verified deletion requests will lead to the removal of
            your data unless legal obligations require otherwise.
          </p>
        </section>

        <section>
          <h2>6. Security Measures</h2>
          <p>
            We use industry-standard measures to protect your data, including
            encryption, access controls, and secure server environments. No
            security system is foolproof, so we encourage strong password usage.
          </p>
        </section>

        <section>
          <h2>7. Your Rights</h2>
          <p>
            You may have certain rights concerning your personal information,
            such as:
          </p>
          <ul>
            <li>Accessing your data</li>
            <li>Correcting inaccuracies</li>
            <li>Requesting data deletion</li>
            <li>Restricting data usage</li>
          </ul>
          <p>
            Contact us at{" "}
            <a href="mailto:contact@applimatch.com">contact@applimatch.com</a>{" "}
            to exercise these rights.
          </p>
        </section>

        <section>
          <h2>8. International Data Transfers</h2>
          <p>
            When transferring your data outside your country, safeguards such as
            standard contractual clauses ensure compliance with applicable laws.
          </p>
        </section>

        <section>
          <h2>9. Children’s Data</h2>
          <p>
            Applimatch is intended for users aged 18 and older. Data collected
            from minors without parental consent will be deleted promptly.
          </p>
        </section>

        <section>
          <h2>10. Updates to This Privacy Policy</h2>
          <p>
            This Privacy Policy may be updated periodically to reflect changes
            in our data practices or applicable regulations. We encourage
            regular review.
          </p>
        </section>

        <section>
          <h2>11. Questions or Concerns</h2>
          <p>
            For any questions or concerns, please contact us at:
            <br />
            Email:{" "}
            <a href="mailto:contact@applimatch.com">contact@applimatch.com</a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
