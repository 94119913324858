// ActionButton.js

import React from "react";

const ActionButton = ({ icon, text, onClick, className }) => {
  return (
    <button
      className={`action-button ${className}`}
      onClick={() => {
        //console.log(`Button "${text}" clicked`); // Debug log
        if (onClick) onClick();
      }}
    >
      {icon && <span className="button-icon">{icon}</span>}
      <span className="button-text">{text}</span>
    </button>
  );
};

export default ActionButton;
