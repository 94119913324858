// src/pages/PaymentCancelled.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentCancelled = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Show cancellation toast
    toast.info("Payment was cancelled. You can try purchasing credits again.");

    // Redirect to Purchase Credits page after 3 seconds
    const timer = setTimeout(() => {
      navigate("/purchase-credits");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="dashboard-container">
      <h2>Payment Cancelled</h2>
      <p>
        Your payment was cancelled. Redirecting you to purchase credits again.
      </p>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default PaymentCancelled;
