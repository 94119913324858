import React, { useEffect } from "react";

const TawkTo = () => {
  useEffect(() => {
    // Initialize Tawk.to script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/675137374304e3196aec9c2c/1ieajvcrp";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    // Append the script to the document
    const scriptTag = document.getElementsByTagName("script")[0];
    scriptTag.parentNode.insertBefore(script, scriptTag);

    return () => {
      // Cleanup: remove the script when the component unmounts
      const tawkScript = document.querySelector(
        `script[src='https://embed.tawk.to/675137374304e3196aec9c2c/1ieajvcrp']`
      );
      if (tawkScript) {
        tawkScript.remove();
      }
    };
  }, []);

  return null; // This component doesn't render anything
};

export default TawkTo;
