import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

const NavItem = styled(Link)`
  margin-left: 20px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.theme === "light" ? "#f7f9fc" : "#333"};
  color: ${(props) => (props.theme === "light" ? "#333" : "#f7f9fc")};
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  width: 15px;
  height: 15px;
  font-size: 1.5rem; /* Adjust icon size */
`;

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <NavItem as="div" onClick={toggleTheme}>
      <IconContainer theme={theme}>
        <FontAwesomeIcon icon={theme === "light" ? faMoon : faSun} />
      </IconContainer>
    </NavItem>
  );
};

export default ThemeToggle;
