import React from "react";
import "../Dashboard.css";

const AddJobButton = ({ onClick }) => {
  return (
    <div className="add-job-button-container">
      <button className="add-job-button" onClick={onClick}>
        <div className="add-job-icon">
          <div className="vertical-line"></div>
          <div className="horizontal-line"></div>
        </div>
        <span className="add-job-text">Add New Job</span>
      </button>
    </div>
  );
};

export default AddJobButton;
