import React from "react";
import PropTypes from "prop-types";
import deleteIcon from "../assets/trash-01.svg";
import "../Dashboard.css";

const DeleteJobButton = ({ onClick }) => (
  <button className="delete-job-button" onClick={onClick}>
    <img src={deleteIcon} alt="Delete Job" />
  </button>
);

DeleteJobButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DeleteJobButton;
