import React from "react";
import "../Dashboard.css";
import cancelIcon from "../assets/cancel.svg"; // Import an SVG for cancel (you may use a specific icon)

const CancelButton = ({ onClick }) => {
  return (
    <button className="cancel-button" onClick={onClick}>
      <img src={cancelIcon} alt="Cancel" />
      {/*<span>Cancel</span>*/}
    </button>
  );
};

export default CancelButton;
